import React, { useState } from 'react'
import clsx from 'clsx'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'

import { validation } from '../../../lib/validation'

import { ReactComponent as ErrorIcon } from '../../../icons/error.svg'

import styles from '../index.module.scss'

const SizeInput = ({ type, changeHandler, blurHandler, focusHandler, message, Icon, isFocused, text, submitErrors }) => {
	const [error, setIsError] = useState({ status: false, value: '', submit: false })

	const changeError = (status, value) => {
		setIsError(prevState => {
			return ({ ...prevState, status: status, value: value, submit: true })
		})
	}

	const onChange = (e, type) => {
		changeError(validation(e.value, type), e.value)
		changeHandler(e)
	}

	if (submitErrors && !error.submit) {
		changeError(true, '')
	}

	return (
		<div message={message} className={clsx(styles.root, {
			[styles.message]: error.status,
			[styles.messageDisable]: error.value !== ''
		})}>
			<div className={styles.inputContent}>
				<div className={clsx(styles.icon, {
					[styles.isIconActive]: isFocused
				})}>{error.status ? <ErrorIcon /> : Icon}</div>
				<div className={clsx(styles.inputText, {
					[styles.isFocused]: isFocused
				})}>{text}</div>
			</div>
			<div className={clsx(styles.input, {
				[styles.error]: error.status
			})}>
				<label style={{ display: 'none' }} htmlFor={type}></label>
				<InputMask
					className={'customInput'}
					mask='99x99x99'
					onFocus={focusHandler}
					onBlur={e => blurHandler(e.target.value, error.status)}
					onChange={e => { onChange(e.target, type) }}
				/>
			</div>
		</div>
	)
}

SizeInput.propTypes = {
	blurHandler: PropTypes.func.isRequired,
	focusHandler: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	changeHandler: PropTypes.func.isRequired,
	message: PropTypes.string.isRequired,
	Icon: PropTypes.object.isRequired,
	isFocused: PropTypes.bool,
	text: PropTypes.string.isRequired,
	submitErrors: PropTypes.bool
}

export default SizeInput