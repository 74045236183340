export const validation = (e, type) => {
	switch(type) {
		case 'weight':
			if(isNaN(e) || e === '') return true
			else return false
		case 'size':
			if(e === '__x__x__' || e === '' || !e.match( /^[0-9]{2}x[0-9]{2}x[0-9]{2}$/)) return true
			else return false
		default:
			return false
	}
}