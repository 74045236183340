import React from 'react'
import clsx from 'clsx'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'

import styles from '../index.module.scss'

import { ReactComponent as ErrorIcon } from '../../../icons/error.svg'

const PhoneInput = ({ type, register, focusHandler, blurHandler, error, message, isFocused, Icon, text }) => {
	let errorVisible = true

	if (error) {
		if (error.ref.value === '') {
			errorVisible = false
		}
	}

	return (
		<div message={message} className={clsx(styles.root, {
			[styles.message]: error,
			[styles.messageDisable]: errorVisible
		})}>
			<div className={styles.inputContent}>
				<div className={clsx(styles.icon, {
					[styles.isIconActive]: isFocused || !errorVisible
				})}>{error ? <ErrorIcon /> : Icon}</div>
				<div className={clsx(styles.inputText, {
					[styles.isFocused]: isFocused || !errorVisible
				})}>{text}</div>
			</div>
			<div className={clsx(styles.input, {
				[styles.error]: error
			})}>
				<label style={{ display: 'none' }} htmlFor={type}></label>
				<InputMask
					className={'customInput'}
					mask='+7(999)999-99-99'
					onFocus={focusHandler}
					onBlurCapture={e => blurHandler(e.target.value === '+7(___)___-__-__' && '', error)}
					name={type}
					id={type}
					{...register(type, { required: true, pattern: /\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/ })}
				/>
			</div>
		</div>
	)
}

PhoneInput.propTypes = {
	blurHandler: PropTypes.func.isRequired,
	focusHandler: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	Icon: PropTypes.object.isRequired,
	isFocused: PropTypes.bool,
	text: PropTypes.string.isRequired
}

export default PhoneInput