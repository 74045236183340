import React from 'react'
import PropTypes from 'prop-types'
import NavigationLink from '../navigationLink'

import styles from './index.module.scss'

const Column = ({ link, title, items }) => {
	return (
		<div className={styles.footerMenuColumn}>
			<div className={styles.footerMenuColumnTitle}>
				<a href={link} className={styles.title}>{title}</a>
			</div>
			<div className={styles.linksWrap}>
				{
					items?.map((e, i) =>
						<div key={i}>
							<NavigationLink key={i} text={e.text} url={e.url}/>
						</div>
					)
				}
			</div>
		</div>
	)
}

Column.propTypes = {
	link: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	items: PropTypes.array
}

export default React.memo(Column)