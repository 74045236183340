import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { ReactComponent as BurgerIcon } from '../../../../../icons/burger.svg'
import { ReactComponent as CloseIcon } from '../../../../../icons/close.svg'

import MenuItemMobile from '../menuItemMobile'
import MenuMainItem from '../menuMainItem'
import { Form } from '../../../../contactsForm'

import { ModalContext } from '../..'

import useWindowDimensions from '../../../../../hooks/useWindowDimensions'

import styles from './index.module.scss'

const MobileMenu = ({ links }) => {
	const [isOpen, setIsOpen] = useState(false)
	const { width } = useWindowDimensions()
	const { setModalComponent, setModalState } = useContext(ModalContext)

	const clickHandler = () => {
		setModalState(true)
		setIsOpen(false)
		setModalComponent(<Form />)
	}

	useEffect(() => {
		if(isOpen) {
			if(width > 960)
				setIsOpen(false)
		}
	}, [isOpen, width])

	const openHandler = () => {
		setIsOpen(!isOpen)
	}

	return (
		<div className={styles.root}>
			<div>
				<div className={clsx(styles.openMenu, {[styles.burgerOpen]: isOpen})} onClick={openHandler}>
					<BurgerIcon />
				</div>
				<div className={clsx(styles.closeMenu, {[styles.openMenuM]: isOpen})}  onClick={openHandler}>
					<CloseIcon />
				</div>
			</div>
			<div className={ clsx({[styles.open]: isOpen}, styles.menu) }>
				<div className={styles.menuItem}>
					<MenuMainItem handler={openHandler} />
				</div>
				{
					links.map((e, i) =>
						<div key={i} className={styles.menuItem}>
							<MenuItemMobile
								handler={openHandler}
								url={e.url}
								text={e.text}
								additionalLinks={e.additionalLinks} />
						</div>
					)
				}
				<div className={styles.button} onClick={clickHandler}><span>Получить</span></div>
			</div>
		</div>
	)
}

MobileMenu.propTypes = {
	links: PropTypes.array.isRequired,
}

export default React.memo(MobileMenu)