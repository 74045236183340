import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { ReactComponent as ForwardIcon } from '../../../../../icons/link-forward-icon.svg'

import styles from './index.module.scss'

const NavigationLink = ({ url, text }) => {
	return (
		<NavLink to={url} className={styles.root}>
			<span className={styles.link}>{text}</span>
			<div className={styles.iconWrapper}>
				<ForwardIcon />
			</div>
		</NavLink>
	)
}

NavigationLink.propTypes = {
	url: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	i: PropTypes.number
}

export default React.memo(NavigationLink)
