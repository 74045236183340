import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import NavigationLink from '../../../../../components/common/navigationLink'
import { ReactComponent as IcoArrowRight } from '../../../../../icons/ico_arrow_right.svg'

import styles from './index.module.scss'

const MenuItem = ({ url, text, additionalLinks, handler }) => {
	const { pathname } = useLocation()
	const match = url.split('/')[1] === pathname.split('/')[1]
	
	return (
		<div className={clsx(styles.root, {
			[styles.isTabActive]: match
		})}>
			<div className={styles.itemTitle}>
				<NavLink to={url} className={styles.linkWrapper} onClick={() => handler()}>
					<div className={clsx(styles.link, {
						[styles.linkActive]: match
					})}>
						{text}
						<IcoArrowRight />
					</div>
				</NavLink>
			</div>
			<div className={styles.additionalContent}>
				{additionalLinks?.map((e, i) =>
					<div key={i}>
						<NavigationLink handler={handler} key={i} text={e.text} url={e.url} isActive={match}/>
					</div>
				)}
			</div>
		</div>
	)
}

MenuItem.propTypes = {
	url: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	additionalLinks: PropTypes.array,
	handler: PropTypes.func.isRequired
}


export default React.memo(MenuItem)