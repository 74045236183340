import linksData from '../../../lib/navigation'

const data = {
	columns: [
		{
			title: 'Услуги',
			link: '#',
			items: [
				{
					title: 'Логистика',
					link: '#'
				},
				{
					title: 'Аэрофотосъёмка',
					link: '#'
				},
				{
					title: 'Точное земледелие',
					link: '#'
				},
			]
		},
		{
			title: 'Флот',
			link: '#',
			items: [
				{
					title: 'Эколибри РК-5',
					link: '#'
				},
				{
					title: 'DJI Mavic 3T',
					link: '#'
				},
				{
					title: 'Autel EVO II',
					link: '#'
				},
				{
					title: 'Эколибри',
					link: '#'
				},
			]
		},
		{
			title: 'О нас',
			link: '/about/',
			items: [
				{
					title: 'Компания',
					link: '#'
				},
				// {
				// 	title: 'Партнёрство',
				// 	link: '#'
				// },
				{
					title: 'Карьера',
					link: '#'
				},
				{
					title: 'ЭПР',
					link: '#'
				},
			]
		}
	],
	items: [
		{
			title: 'Главная',
			link: '/'
		},
		{
			title: 'Услуги',
			link: '/services/'
		},
		{
			title: 'Флот',
			link: '/fleet/'
		},
		{
			title: 'О нас',
			link: '/about/'
		},
	],
	politics: [
		{
			title: 'Политика по обработке персональных данных',
			link: '/assets/personal_data_politics.pdf',
		},
		{
			title: 'Пользовательское соглашение при использовании сайта',
			link: '/assets/license_agreement.pdf',
		}
	],
	links: linksData.links,
};

export default data