import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from "prop-types";

import styles from './index.module.scss'

const Link = ({ link, text }) => {
	return (
		<NavLink target={'_blank'} to={link} className={styles.root}>
			<span className={styles.footerLink}>{text}</span>
		</NavLink>
	)
}

Link.propTypes = {
	link: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired
}

export default React.memo(Link)