const data = {
  links: [
    {
      text: "Услуги",
      icon: "services-icon.svg",
      count: null,
      url: "/services/",
      additionalLinks: [
        {
          text: "Логистика",
          url: "/services/logistics/",
        },
        {
          text: "Аэрофотосъемка",
          url: "/services/aero/",
        },
        {
          text: "Точное земледелие",
          url: "/services/agro/",
        },
      ],
    },
    {
      text: "Флот",
      icon: "fleet-icon.svg",
      count: null,
      url: "/fleet/",
      additionalLinks: [
        {
          text: "Логистика",
          url: "/fleet/logistics/",
        },
        {
          text: "Аэрофотосъемка",
          url: "/fleet/aero/",
        },
        {
          text: "Точное земледелие",
          url: "/fleet/agro/",
        },
      ],
    },
    {
      text: "О нас",
      icon: "about-icon.svg",
      count: null,
      url: "/about/",
      additionalLinks: [
        {
          text: "Компания",
          url: "/about/",
        },
        {
          text: "Карьера",
          url: "/about/career",
        },
        {
          text: "ЭПР",
          url: "/about/epr/",
        },
      ],
    },
  ],
};

export default data