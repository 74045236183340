import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CloseIcon } from '../../../icons/close-popup.svg'

import styles from './index.module.scss'

const Modal = ({ popupContent, isVisible, changeModalState }) => {
	const [content, setContent] = useState(null)

	const closePopUp = () => {
		changeModalState(false)
	}

	useEffect(() => {
		const escapeDownHandler = (e) => {
			if(e.key === 'Escape')
				changeModalState(false)
		}

		document.addEventListener('keydown', escapeDownHandler)

		return () => document.removeEventListener('keydown', escapeDownHandler)
	}, [changeModalState])

	useEffect(() => {
		setContent(popupContent)
	}, [popupContent])

	if(!isVisible)
		return null

	return (
		<div className={styles.root}>
			<div className={styles.bg} onClick={closePopUp}>
				<div className={styles.buttonWrapper}>
					<div className={styles.close} onClick={closePopUp}>
						<CloseIcon />
					</div>
				</div>
			</div>
			<div className={styles.content}>
				{content}
			</div>
		</div>

	)
}

Modal.propTypes = {
	popupContent: PropTypes.element,
	isVisible: PropTypes.bool.isRequired,
	changeModalState: PropTypes.func.isRequired,
}

export default React.memo(Modal)
